var lastScroll = 0;

jQuery(document).ready(function($) {
    $("body").removeClass("scrolled");

    $(window).scroll(function(){
        setTimeout(function() {
            var scroll = $(window).scrollTop();
            if (scroll > lastScroll + 30) {
                $("body").addClass("scrolled");
            } else if (scroll < lastScroll - 30) {
                $("body").removeClass("scrolled");
            }
            lastScroll = scroll;
        }, 200);
    });
});

$(".open-lightbox-impressum").click(function(){
    $("body").toggleClass("opened-lightbox-impressum");
});
// $(".open-lightbox-privacy").click(function(){
//     $("body").toggleClass("opened-lightbox-privacy");
// });
